type Config = {
  appName: string;
  appOrigin: string;
  apiUrl: string;
  storageBaseUrl: string;
  authProviders: string[];
  socialLinks: {
    title: string;
    link: string;
  }[];
  abuseEmail: string;
  webrtcIceServers: any;
};

const config: Config = {
  appName: 'Kavykhi Cloud',
  appOrigin: 'https://kavykhi.cloud',
  apiUrl: 'https://kavykhi.cloud/api',
  storageBaseUrl: 'https://kavykhi.cloud/storage/',

  authProviders: [
    'steam',
    'github',
    'gitlab',
    'itch',
    'twitch',
    'discord',
  ],

  socialLinks: [
    {
      title: 'discord',
      link: 'https://discord.gg/kDxD6CYmAC',
    },
  ],

  abuseEmail: 'abuse@kavykhi.cloud',

  webrtcIceServers: [
  ],
};

export default config;
